import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';
import Home from "./screens/Home";
import Voting from "./screens/Voting";
import Community from "./screens/Community";
import { useEagerConnect, useInactiveListener } from './hooks/useEagerConnect';
import GetSubscription from "./components/GetSubscription";
const {ethereum} = window;
function App() {
  const metamask_url = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'
  const [errorMessage, setErrorMessage] = useState();
  useEagerConnect(setErrorMessage);
  useInactiveListener();
  useEffect(()=>{
    if (!ethereum){
    if (window.confirm("Please install metamask wallet and select ethereum network")) {
      // window.location.href = metamask_url;
      // window.open(metamask_url, '_blank');
      document.getElementById("metamast_link").click()
    } else {
      // window.location.href = metamask_url;
    }
    }  
  },[])
  window.ethereum && ethereum.on("chainChanged", async()=>{
    window.location.reload();
  });
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/voting" element={<Voting />} />
          <Route path="/community" element={<Community />} />
          <Route path='/get-subscription' element={<GetSubscription/>}/>
        </Routes>
      </BrowserRouter>
      <a href={metamask_url} target="_blank" id="metamast_link" className="d-none"></a>
    </div>
  );
}

export default App;
